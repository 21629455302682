/* .chat-header {
    background-color: #007bff;
    color: white;
    padding: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    text-align: center;
  } */

  .chat-header {
    align-items: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
    font-size: 1.5em;
    padding: 0 10px 10px;
  }

  .chat-header-company {
    margin-left: 10px;
  }

  .chat-header-icon {
    color: #007bff;
    cursor: pointer;  
  }

  .default {
    font-style: italic;
  }