.dropdown, .text-input {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

label {
    margin-bottom: 5px;
}

.dropdown-input {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
}

.text-input-input {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
}