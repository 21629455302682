.info-modal-body {
    display: flex;
    flex-direction: column;
    text-align: start;
    width: 500px;
}

.info-modal-button {
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.info-modal-button-submit {
    background-color: #007bff;
    color: white;
}

.info-modal-button-submit:disabled {
    background-color: rgba(0,0,0,0.5);
}

.info-modal-error {
    color: red;
}