.tag {
    border-radius: 5px;
    color: white;
    cursor: pointer;
    margin-bottom: 10px;
    padding: 8px 12px;
}

.tag-info {
    background-color: #007bff;
}

.tag-prompt {
    background-color: green;
}