/* .App {
  text-align: center;
} */

.App-header {
  background-color: #282c34;
  padding: 20px;
  color: white;
}

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f0f0f0;
}

#app {
  margin: 20px;
}

h1 {
  color: #333;
}

/* Used for styling the tags in the response messages */
h3, h4 {
  margin-bottom: 0px;
  margin-top: 10px;
}

.App-body {
  display: flex;
  justify-content: center;
}

.header {
  font-size: 1.5em;
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px;
}

.pr-20 {
  padding-right: 20px;
}