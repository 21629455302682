/* src/components/ChatWindow.css */
.chat-window {
    /* position: fixed;
    bottom: 70px;
    right: 20px; */
    /* width: 300px; */
    /* height: 400px; */
    width: 100%;
    height: 95vh;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    padding: 20px;
  }
    
  .chat-messages {
    border: 1px solid #aaa;
    border-radius: 5px;
    flex: 1;
    padding: 10px;
    overflow-y: auto;
  }
  
  .chat-message {
    margin-bottom: 10px;
    padding: 8px 12px;
    border-radius: 5px;
  }
  
  .chat-message.user {
    background-color: #007bff;
    color: white;
    align-self: flex-end;
  }
  
  .chat-message.bot {
    background-color: #f0f0f0;
    color: black;
    align-self: flex-start;
  }
  
  .chat-input {
    display: flex;
    padding-top: 10px;
    /* border-top: 1px solid #ccc; */
  }
  
  .chat-input input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 10px;
  }
  
  .chat-input button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .chat-input button:hover {
    background-color: #0056b3;
  }
  
  .blur-bg {
    filter: blur(1px);
    pointer-events: none;
  }