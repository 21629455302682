.react-tabs__tab-list {
    margin-bottom: 0px;
}

.react-tabs__tab-panel {
    flex-direction: column;
    overflow-y: auto;
    padding: 10px;
}

.rotate180 {
    transform: rotate(180deg);
}

.tab-container {
    border: 1px solid #aaa;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    max-height: 100px;
    min-height: 100px;
    overflow-y: auto;
}

.tab-container-icon-row {
    display: flex;
    justify-content: end;
    padding-right: 10px;
    position: relative;
    top: 20px;
}

.tab-container-icon-row-icon {
    cursor: pointer;
    transition: all 0.5 ease;
}

.tab-container-expanded {
    max-height: 251px;
    min-height: 251px;
}
